import React from 'react'
import styled, { css } from 'styled-components'
import to from 'await-catch'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import {
  getMOLIAppointments,
  getCancelReasons,
  cancelAppointment,
  connectToAppointment
} from '../api'
import FormCancelAppointment from './FormCancelAppointment'
import { Cell, TableContainer } from '../styled/tables'

const columns = [
  { title: 'Número de solicitud', size: '0.5fr' },
  { title: 'Profesional', size: '1fr' },
  { title: 'Fecha y hora', size: '1fr' },
  { title: '', size: 'auto' },
  { title: '', size: 'auto' }
]

class TableMegoliAppointments extends React.Component {
  state = { listOfAppointments: [], searchedAndNoData: false, cancelReasons: [] }

async componentDidMount() {
  const [appointmentsResponse, reasonsResponse] = await Promise.all([
    getMOLIAppointments(this.props.patient.idPaciente),
    getCancelReasons(this.props.appointment.activeClinic.codigoEmpresa)
  ]).catch(err => {
    console.error("Error fetching data: ", err);
    return [null, null];
  });

  if (appointmentsResponse && reasonsResponse) {
    const { data: appointments } = appointmentsResponse;
    const { data: reasons } = reasonsResponse;

    this.setState({
      cancelReasons: reasons,
      searchedAndNoData: appointments.length <= 0,
      listOfAppointments: appointments.map(appointment => ({
        ...appointment,
        showPanelCancel: false,
        cancelled: false
      }))
    });
  }
}


  renderCancelIcon = (index, selected) => (
    <CancelButton
      warning={selected}
      className="material-icons"
      onClick={() => this.openCancelPanel(index)}
    >
      delete
    </CancelButton>
  )

  renderConnectIcon = (index, available, tooltip) => (
    <TooltipContainer
      onMouseEnter={() => this.toogleTooltip(index, available, true)}
      onMouseLeave={() => this.toogleTooltip(index, available, false)}
    >
      <ConnectButton
        disabled={!available}
        className="material-icons"
        onClick={() => this.handleConnectToAppointment(index, available)}
      >
        videocam
      </ConnectButton>
      {tooltip && <Tooltip>5 min antes</Tooltip>}
    </TooltipContainer>
  )

  renderPanelCancel = index => (
    <FormCancelAppointment
      onCancel={() => this.openCancelPanel(index)}
      onSubmit={this.handleCancelAppointment}
      cancelReasons={this.state.cancelReasons}
      appointment={index}
    />
  )

  openCancelPanel = index => {
    const appointments = this.state.listOfAppointments
    appointments[index].showPanelCancel = !appointments[index].showPanelCancel
    this.setState({ listOfAppointments: appointments })
  }

  toogleTooltip = (index, available, value) => {
    if (!available) {
      const appointments = this.state.listOfAppointments
      appointments[index].showTooltip = value
      this.setState({ listOfAppointments: appointments })
    }
  }

  handleCancelAppointment = async (values, index) => {
    const appointments = this.state.listOfAppointments
    const [err] = await to(
      cancelAppointment(
        appointments[index].idTurno,
        appointments[index].codigoEmpresa,
        values.reason
      )
    )
    if (!err) {
      appointments[index].cancelled = true
      this.setState({ listOfAppointments: appointments })
    }
  }

  handleConnectToAppointment = async index => {
    const appointments = this.state.listOfAppointments

    if (!appointments[index].habilitado) {
      return
    }

    var params = {
      phone: this.props.patient.telefono,
      idTurno: appointments[index].idTurno
    }

    const [err, { data } = {}] = await to(connectToAppointment(params))
    if (!err) {
      window.open(data, '_blank')
      this.props.history.push('/')
    }
  }

  render() {
    return (
      <React.Fragment>
        {this.state.searchedAndNoData ? (
          <NoData>No tenés turnos agendados.</NoData>
        ) : (
          <TableContainer columns={columns.map(({ size }) => size).join(' ')}>
            {columns.map(({ title }, index) => (
              <Cell key={index} header>
                {title}
              </Cell>
            ))}

            {this.state.listOfAppointments.map(
              (appointment, index) =>
                appointment.cancelled ? (
                  <CancelledCell>Tu turno ha sido cancelado</CancelledCell>
                ) : (
                  <React.Fragment key={index}>
                    <Cell red={appointment.showPanelCancel}>{appointment.idTurno}</Cell>
                    <Cell red={appointment.showPanelCancel}>{appointment.nombreProfesional}</Cell>
                    <Cell red={appointment.showPanelCancel}>{appointment.fechaTurno}</Cell>
                    <Cell red={appointment.showPanelCancel}>
                      {this.renderConnectIcon(
                        index,
                        appointment.habilitado,
                        appointment.showTooltip
                      )}
                    </Cell>
                    <Cell red={appointment.showPanelCancel}>
                      {this.renderCancelIcon(index, appointment.showPanelCancel)}
                    </Cell>
                    {appointment.showPanelCancel && (
                      <PanelCell>{this.renderPanelCancel(index)}</PanelCell>
                    )}
                  </React.Fragment>
                )
            )}
          </TableContainer>
        )}
      </React.Fragment>
    )
  }
}

// export default withAppointment(TableMegoliAppointments)
const mapDispatchToProps = {}
function mapStateToProps({ appointment, auth, patient }) {
  return { appointment, auth, patient }
}
export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(TableMegoliAppointments))

/*
 * Styles
 */
const CancelButton = styled.i`
  font-size: 20px;
  color: ${props => props.theme.colors.redThree};
  cursor: pointer;
  ${props =>
    props.warning &&
    css`
      color: ${props => props.theme.colors.redTwo};
    `};
`

const ConnectButton = styled.i`
  font-size: 20px;
  color: ${props => props.theme.colors.green};
  cursor: pointer;
  ${props =>
    props.disabled &&
    css`
      color: ${props => props.theme.colors.redThree};
      cursor: default;
    `};
`

const tooltipContainerCSS = css`
  position: relative;
  display: inline-block;
`

const TooltipContainer = styled.div`
  ${tooltipContainerCSS};
`

const Tooltip = styled.div`
  ${tooltipContainerCSS};

  font-size: 14px;
  width: 80px;
  background: white;
  color: ${props => props.theme.colors.greenThree};
  text-align: center;
  border-radius: 6px;
  padding: 0 0;

  position: absolute;
  top: 0px;
  right: 30px;
  z-index: 1;
`

const NoData = styled.div`
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  grid-column: 1/-1;
  color: ${props => props.theme.colors.redTwo};
  padding: 36px 0;
  margin-top: 5px;
  background: ${props => props.theme.colors.red};
`
const CancelledCell = styled(Cell)`
  color: ${props => props.theme.colors.redTwo};
  font-weight: 600;
  font-size: 16px;
  padding: 13px 0;
  grid-column: 1/-1;
  justify-self: center;
`
const PanelCell = styled(Cell)`
  padding: 31px 0 40px 0;
  height: auto;
  grid-column: 1/-1;
`
