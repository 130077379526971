import FUNDER_LEFT from '../images/funder-left.jpg'
import FUNDER_RIGHT from '../images/funder-right.jpg'
import PATIENT_LEFT from '../images/patient-left.jpg'
import PATIENT_RIGHT from '../images/patient-right.jpg'
import ADMIN_LEFT from '../images/admin-left.jpg'
import ADMIN_RIGHT from '../images/admin-right.jpg'
import MOLI from '../images/moli.jpg'
import PREGNANCY_LOGO from '../images/pp_image_portal.jpg'
import SCHELDURE_MOLI from '../images/scheldure_moli.jpg'
import LAB_RESULTS from '../images/lab_results.jpg'
import WOMEN_CENTER_IMG from '../images/women-center.jpg'
import SPORT_MEDICINE_IMG from '../images/sport-medicines.jpg'

import config from '../config'
import { WOMEN_CENTER, SPORT_MEDICINE, FACE_TO_FACE } from './specialtiesGroups'


import {
    PATIENT_GROUP,
    FUNDER_GROUP,
    ADMINISTRATOR_GROUP,
    AUTOMANAGEMENTFUNDER_GROUP,
    MEDIC_GROUP,
    PREGNANCYPLANNER_GROUP,
    BILLING_GROUP,
    PATIENT,
    FUNDER,
    FUNDERMEDIC,
    FUNDERADMINISTRATIVE,
    ADMINISTRATOR,
    AUTOMANAGEMENTFUNDER,
    MEDIC,
    PREGNANCYPLANNER,
    BILLING
} from './roles'

import {
    DUTIES,
    HOSPITALIZED_PATIENTS,
    FUTURE_APPOINTMENTS,
    LABORATORIES,
    USERS,
    ROLES,
    MEGOLI,
    PREGNANCYPLANNER_USER_PERMIT,
    PREGNANCYPLANNER_ADMIN_PERMIT,
    BILLING_PERMIT,
    LOGS
} from './permits'
import features from '../features'

export const ROUTES_BY_ROLE_GROUP = {
    [PATIENT_GROUP]: [
        {
            name: 'Ir a la guardia',
            pathName: '/inicio/guardia-smart',
            icon: null,
            permit: FUTURE_APPOINTMENTS,
            roles: [PATIENT],
            cta: 'Pedí turno',
            buttonImage: PATIENT_LEFT,
            disable: features.disableClinicGuard
        },
        {
            name: 'Videoconsulta programada',
            pathName: '/inicio/videoconsulta/turnos/listado',
            permit: MEGOLI,
            roles: [PATIENT],
            cta: 'Más información',
            buttonImage: SCHELDURE_MOLI,
            modalMessage: 'Para coordinar una videoconsulta, comunicate con nuestro \n  Contact Center al 0800-666-6587. \n\n ¡Discúlpanos las molestias! Estamos trabajando en la mejora de este servicio.'
        },
        {
            name: 'Atención presencial',
            pathName: `/inicio/gestionar-turnos/${FACE_TO_FACE}`,
            permit: FUTURE_APPOINTMENTS,
            roles: [PATIENT],
            cta: 'Pedí turno',
            buttonImage: PATIENT_RIGHT
        },
        {
            name: 'Resultados de laboratorio',
            pathName: '/inicio/laboratorios/confirmar',
            permit: LABORATORIES,
            roles: [PATIENT],
            cta: 'Consultá',
            buttonImage: LAB_RESULTS
        },
        {
            isExternalLink: true,
            isPregnant: true,
            name: 'Pregnancy Planner',
            pathName: `${config.endpoints.PREGNANCYPLANNER_URL}/login`,
            permit: PREGNANCYPLANNER_USER_PERMIT,
            roles: [PATIENT],
            cta: 'Ingresar',
            buttonImage: PREGNANCY_LOGO
        },
        {
            name: 'Atención presencial deportología',
            pathName: `/inicio/gestionar-turnos/${SPORT_MEDICINE}`,
            permit: FUTURE_APPOINTMENTS,
            roles: [PATIENT],
            cta: 'Pedí turno',
            buttonImage: SPORT_MEDICINE_IMG,
            show: features.enableNewSpecialties
        },
        {
            name: 'Atención presencial centro de la mujer',
            pathName: `/inicio/gestionar-turnos/${WOMEN_CENTER}`,
            permit: FUTURE_APPOINTMENTS,
            roles: [PATIENT],
            cta: 'Pedí turno',
            buttonImage: WOMEN_CENTER_IMG,
            show: features.enableNewSpecialties
        }
    ],
    [MEDIC_GROUP]: [
        {
            name: 'Videoconsulta',
            pathName: '/inicio/videoconsulta-medico',
            permit: MEGOLI,
            roles: [MEDIC],
            cta: 'Ingresar',
            buttonImage: MOLI
        }
    ],
    [FUNDER_GROUP]: [
        {
            name: 'Pacientes Internados',
            pathName: '/inicio/pacientes-internados',
            permit: HOSPITALIZED_PATIENTS,
            roles: [FUNDER, FUNDERMEDIC],
            cta: 'Ver todos',
            buttonImage: FUNDER_LEFT
        },
        {
            name: 'Aranceles',
            pathName: '/inicio/aranceles',
            permit: DUTIES,
            roles: [FUNDER, FUNDERADMINISTRATIVE],
            cta: 'Ver todos',
            buttonImage: FUNDER_RIGHT
        },
        {
            name: 'Facturación',
            pathName: '/inicio/facturacion',
            permit: BILLING_PERMIT,
            roles: [FUNDERADMINISTRATIVE],
            cta: 'Ver todos',
            buttonImage: FUNDER_RIGHT
        }
    ],
    [ADMINISTRATOR_GROUP]: [
        {
            name: 'Usuarios',
            pathName: '/inicio/usuarios',
            permit: USERS,
            roles: [ADMINISTRATOR],
            cta: 'Ver más',
            buttonImage: ADMIN_LEFT
        },
        {
            name: 'Roles',
            pathName: '/inicio/roles',
            permit: ROLES,
            roles: [ADMINISTRATOR],
            cta: 'Ver más',
            buttonImage: ADMIN_RIGHT
        },
        {
            name: 'Reporte de Accesos',
            pathName: '/inicio/logs',
            permit: LOGS,
            roles: [ADMINISTRATOR],
            cta: 'Ver logs',
            buttonImage: FUNDER_RIGHT
        }
    ],
    [AUTOMANAGEMENTFUNDER_GROUP]: [
        {
            isPregnant: true,
            name: 'Usuarios',
            pathName: '/inicio/usuarios',
            permit: USERS,
            roles: [AUTOMANAGEMENTFUNDER],
            cta: 'Ver más',
            buttonImage: ADMIN_LEFT
        }
    ],
    [PREGNANCYPLANNER_GROUP]: [
        {
            name: 'Usuarios',
            pathName: '/inicio/usuarios',
            permit: USERS,
            roles: [PREGNANCYPLANNER],
            cta: 'Ver más',
            buttonImage: ADMIN_LEFT
        },
        {
            isPregnancyAdmin: true,
            isExternalLink: true,
            name: 'Pregnancy Planner',
            pathName: `${config.endpoints.PREGNANCYPLANNER_URL}/login`,
            permit: PREGNANCYPLANNER_ADMIN_PERMIT,
            roles: [PREGNANCYPLANNER],
            cta: 'Ver más',
            buttonImage: ADMIN_LEFT
        }
    ],
    [BILLING_GROUP]: [
        {
            name: 'Facturación',
            pathName: '/inicio/facturacion',
            permit: BILLING_PERMIT,
            roles: [BILLING],
            cta: 'Ver todos',
            buttonImage: FUNDER_RIGHT
        }
    ]
}