import React, { Fragment } from 'react'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'
import { Button } from '../styled/generics'
import { Link } from 'react-router-dom'
import { DISABLED_FEATURE_MESSAGE } from '../constants'
import DashboardButtonMessage from './DashboardButtonMessage'
import Modal, { ActionContainer, ModalContainer } from './Modal'

const ModalMessage = props => {
  return (
    <Modal>
      <ModalContainer style={{ whiteSpace: 'pre-wrap' }}>
        <h3>{props.modalMessage}</h3>
        <ActionContainer>
          <Button onClick={props.action}>Aceptar</Button>
        </ActionContainer>
      </ModalContainer>
    </Modal>
  )
}

class DashboardButton extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isModalOpen: false
    }
  }

  handleClick = () => {
    if (this.props.modalMessage) {
      this.setState({ isModalOpen: true })
    }
  }

  closeModal = () => {
    if (this.props.modalMessage) {
      this.setState({ isModalOpen: false })
    }
  }

  render() {
    const { isModalOpen } = this.state
    const {
      image,
      title,
      cta,
      link,
      isExternalLink,
      disable,
      notification,
      modalMessage
    } = this.props
    let showModalMessage = modalMessage
    let showNotifications = notification
    let extraMargin = showNotifications ? 1 : 0

    if (disable) {
      showModalMessage = null
      showNotifications = false
      cta = DISABLED_FEATURE_MESSAGE
      extraMargin = 0
    }

    const dashboardContent = (
      <Fragment>
        {showNotifications && <DashboardButtonMessage message={notification} />}
        <Title>{title}</Title>
        <ActionButton disabled={disable} outline>
          {cta}
        </ActionButton>
      </Fragment>
    )

    return (
      <div>
        {isModalOpen && <ModalMessage action={this.closeModal} modalMessage={showModalMessage} />}
        {this.props.show &&
          (isExternalLink ? (
            <Anchor image={image} href={link} extra_margin={extraMargin} disable={disable}>
              {dashboardContent}
            </Anchor>
          ) : (
            <Container
              image={image}
              to={!showModalMessage ? link : '#'}
              extra_margin={extraMargin}
              disable={disable}
              onClick={this.handleClick}
            >
              {dashboardContent}
            </Container>
          ))}
      </div>
    )
  }
}

DashboardButton.propTypes = {
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  cta: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  isExternalLink: PropTypes.bool,
  disable: PropTypes.bool,
  show: PropTypes.bool,
  notification: PropTypes.string,
  modalMessage: PropTypes.string
}

DashboardButton.defaultProps = {
  disable: false,
  show: true
}

export default DashboardButton

/*
 * Styles
 */

const ContainerCSS = css`
      ${props =>
        props.disable &&
        css`
          pointer-events: none;
        `};
      display: flex;
      width: 200px;
      height: 210px;
      flex-direction: column;
      justify-content: center;
      text-align: center;
      padding: 2%;
      text-decoration: none;
      position: relative;
      background: url(${props => props.image}) center / cover no-repeat;
      box-shadow: inset 0 0 0 1000px ${props =>
        props.disable ? 'rgb(162 157 157 / 0.8);' : 'rgba(4, 112, 94, 0.7);'};
      margin: 0px 5px 45px;

      @media (max-width: ${props => props.theme.sizes.breakPoints.small}) {
      padding-top: 40px;
      padding-bottom: 25px;
      width: 100%;
      height: auto;
      margin-bottom: 0;
      &:not(:first-child) {
        margin-left: 0;
      margin-top: 20px;
    }
      ${props =>
        props.extra_margin &&
        css`
          &:not(:first-child) {
            margin-top: 50px;
          }
        `};
    }

      &:hover {
            box-shadow: inset 0 0 0 1000px rgba(4, 112, 94, 0.85);
      }
`

const Container = styled(Link)`
  ${ContainerCSS};
`
const Anchor = styled.a`
  ${ContainerCSS};
`

const Title = styled.h2`
  font-size: 26px;
  color: white;
  margin-top: auto;
  margin-bottom: auto;

  @media (max-width: ${props => props.theme.sizes.breakPoints.small}) {
    font-size: 24px;
    margin-bottom: 20px;
  }
`
const ActionButton = styled(Button)`
  ${props =>
    props.disabled &&
    css`
      white-space: pre-wrap;
      background: transparent;
      border-color: ${props => props.theme.colors.grey};
      box-shadow: inset 0 0 0 1000px rgb(162 157 157 / 0);
      border: 1px solid white;
    `}
`
