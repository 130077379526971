import React from 'react'
import { connect } from 'react-redux'
import styled, { css } from 'styled-components'
import moment from 'moment'
import to from 'await-catch'
import { withRouter } from 'react-router-dom'

import { getLinePosition, cancelCheckInAppointment } from '../api'
import { Button, LinkButton, Paragraph } from '../styled/generics'
import ModalConfirmation from './ModalConfirmation'

class ClinicGuardCheckInLine extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      position: null,
      intervalId: null,
      showCancelModal: false,
      timeLeft: 0,
      appointment: {
        clinicId: this.props.checkIn.id,
        clinicName: this.props.checkIn.name,
        schedule: moment(this.props.checkIn.waitingTime)
      }
    }
  }

  componentDidMount() {
    this.updatePosition()
    const intervalId = setInterval(this.updateTimeLeft, 60000)
    this.setState({
      intervalId,
      timeLeft: Math.ceil(moment(this.state.appointment.schedule).diff(moment(), 'minutes', true))
    })
  }

  componentWillUnmount() {
    clearInterval(this.state.intervalId)
  }

  updateTimeLeft = () =>
    this.setState({
      timeLeft: Math.ceil(moment(this.state.appointment.schedule).diff(moment(), 'minutes', true))
    })

  updatePosition = async () => {
    const [err, { data } = {}] = await to(
      getLinePosition(this.state.appointment.clinicId, this.props.checkIn.turno)
    )
    if (!err) {
      this.setState({ position: data })
    }
  }

  renderClinicMessage = () => {
    return this.props.checkIn.nuevo ? (
      <P green bold>
        {`¡Ya estás en la fila de la guardia de ${this.state.appointment.clinicName}!`}
      </P>
    ) : (
      <P green bold>
        Ya tenés una reserva en {this.state.appointment.clinicName} a las{' '}
        {this.state.appointment.schedule.format('HH:mm')}
      </P>
    )
  }

  renderTimeLeft = () => {
    const oneMinute = this.state.timeLeft === 1
    return (
      <TimeToAppointment>
        {this.state.timeLeft > 0 ? (
          <React.Fragment>
             <P>{oneMinute ? 'Falta ' : 'Faltan '}
            <time>
              {this.state.timeLeft}
              {oneMinute ? ' minuto ' : ' minutos '}
            </time>
            para tu atención</P>
          </React.Fragment>
        ) : (
          '¡Te esperamos en la clínica!'
        )}
      </TimeToAppointment>
    )
  }

  toggleModal = () => this.setState({ showCancelModal: !this.state.showCancelModal })

  confirmCancelCheckIn = async () => {
    const params = {
      clinica: this.state.appointment.clinicId,
      turno: this.props.checkIn.turno
    }
    const [err] = await to(cancelCheckInAppointment(params))
    if (!err) {
      this.props.history.push(`${this.props.match.url}/cancelado`)
    }
  }

  render() {
    return (
      <React.Fragment>
        <Container>
          <CheckInLineInfo>
            {this.renderClinicMessage()}
            <P bold>
              Cuando llegues a la clínica, mostrá este comprobante en la ventanilla exclusiva Guardia Ágil.
            </P>
            <PositionInfo>
              {this.renderTimeLeft()}
              <P>
                Tiempo de tolerancia: hasta las{' '}
                <time>
                  {moment(this.state.appointment.schedule)
                    .add(1, 'h')
                    .format('HH:mm')}
                </time>
              </P>
              <Position>
                Tu posición es <Number>#{this.state.position}</Number>
              </Position>
              <P small  style={{ margin: "32px 0"}}> Recuerde: la prioridad en la atención está dada por el problema de salud identificado y no por el lugar en la fila asignado.<br></br></P>
              <P small>
                Hacé clic en el botón para ver la progresión de la fila
              </P>
              <IconButton outline green onClick={this.updatePosition}>
                <Icon className="material-icons">loop</Icon>
              </IconButton>
            </PositionInfo>
          </CheckInLineInfo>
          <ButtonsContainer>
            <LinkButton to="/inicio/guardia-smart">Volver</LinkButton>
            <Button onClick={this.toggleModal}>Salir</Button>
          </ButtonsContainer>
        </Container>
        {this.state.showCancelModal && (
          <ModalConfirmation
            iconCode="help"
            message="¿Querés abandonar la fila?"
            buttonCancel={{ action: this.toggleModal, label: 'No' }}
            buttonConfirm={{ action: this.confirmCancelCheckIn, label: 'Sí' }}
          />
        )}
      </React.Fragment>
    )
  }
}

function mapStateToProps({ smartGuard: { clinicsTimes, speciality, checkIn } }) {
  return { clinicsTimes, speciality, checkIn }
}
export default withRouter(connect(mapStateToProps)(ClinicGuardCheckInLine))

const Container = styled.div`
  margin-top: 40px;
`
const CheckInLineInfo = styled.div`
  width: 400px;
  text-align: center;
  margin: auto;
  @media (max-width: ${props => props.theme.sizes.breakPoints.small}) {
    width: 100%;
  }
`
const P = styled(Paragraph)`
  margin: 0;
  color: ${props => props.theme.colors.warmGreyTwo};
  @media (max-width: ${props => props.theme.sizes.breakPoints.small}) {
    font-size: 13px;
  }

  ${props =>
    props.green &&
    css`
      color: ${props => props.theme.colors.green};
      font-size: 22px;
      margin-bottom: 20px;
      @media (max-width: ${props => props.theme.sizes.breakPoints.small}) {
        font-size: 22px;
      }
    `};
  ${props =>
    props.bold &&
    css`
      font-weight: 600;
    `};
  ${props =>
    props.small &&
    css`
      font-size: 14px;
      @media (max-width: ${props => props.theme.sizes.breakPoints.small}) {
        font-size: 12px;
      }
    `};
  > time {
    font-weight: 600;
  }
`
const PositionInfo = styled.div`
  width: 360px;
  padding: 38px 27px;
  margin: 27px auto 27px auto;
  background: white;
  @media (max-width: ${props => props.theme.sizes.breakPoints.small}) {
    width: 100%;
    padding: 12px 30px;
    margin: 10px auto 10px auto;
  }
`
const TimeToAppointment = styled(Paragraph)`
  color: ${props => props.theme.colors.green};
  font-size: 36px;
  line-height: 37px;
  margin-bottom: 20px;
  > time {
    font-size: 42px;
    font-weight: 600;
  }
  @media (max-width: ${props => props.theme.sizes.breakPoints.small}) {
    font-size: 22px;
    line-height: 22px;
    margin-bottom: 10px;
    > time {
      font-size: 25px;
    }
  }
`
const Position = styled(Paragraph)`
  color: ${props => props.theme.colors.green};
  font-size: 22px;
  margin-bottom: 20px;
  font-weight: 600;
  @media (max-width: ${props => props.theme.sizes.breakPoints.small}) {
    font-size: 13px;
  }
`
const Number = styled.span`
  font-size: 42px;
  @media (max-width: ${props => props.theme.sizes.breakPoints.small}) {
    font-size: 25px;
  }
`
const IconButton = styled(Button)`
  width: 100px;
  margin: 20px auto 0 auto;
`
const Icon = styled.i`
  font-size: 26px !important;
  vertical-align: middle;
  @media (max-width: ${props => props.theme.sizes.breakPoints.small}) {
    font-size: 22px !important;
  }
`
const ButtonsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 40px;
  @media (max-width: ${props => props.theme.sizes.breakPoints.small}) {
    grid-template-columns: 1fr;
    grid-gap: 20px;
  }
`
