import React from 'react'
import ReactPaginate from 'react-paginate'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { TableContainer, Cell } from '../styled/tables'

const ARROW_DESC = 'arrow_downward'
const ARROW_ASC = 'arrow_upward'
const ARROW_NONE = 'swap_vert'

class Table extends React.Component {
  constructor(props) {
    super(props)

    const columns = props.columns.map(column => {
      if (column.title === 'Descargas' || typeof column.render === 'function') {
        return { ...column, order: 'none', sortable: false, icon: '' }
      }

      return {
        title: column.title,
        index: column.index,
        order: 'none',
        icon: ARROW_NONE,
        size: column.size,
        sortable: true
      }
    })

    props.useCheckboxes &&
      columns.unshift({
        index: 'select',
        size: props?.columns[0]?.size,
        type: 'checkbox'
      })

    const defaultSortedIndex = columns[0].index

    this.state = {
      sortedIndex: !props.sortedIndex ? defaultSortedIndex : props.sortedIndex,
      noDataMsg: 'No se encontraron registros para los parametros ingresados',
      rows: props.rows,
      columns: columns,
      allRowsSelected: false
    }
  }

  static propTypes = {
    columns: PropTypes.arrayOf(
      PropTypes.shape({
        index: PropTypes.string,
        title: PropTypes.string.isRequired,
        render: PropTypes.func
      })
    ).isRequired,
    rows: PropTypes.array.isRequired,
    totalPages: PropTypes.number,
    itemsPerPage: PropTypes.number,
    sortedIndex: PropTypes.string,
    onClickPage: PropTypes.func
  }

  static defaultProps = {
    totalPages: 0,
    itemsPerPage: 10
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      rows: nextProps.rows.map((row, idx) => ({ ...row, id: idx, selected: false }))
    })
  }

  componentDidUpdate() {
    if (this.props.rows.length === 0 && this.state.rows.length === 0) return
    const selectedItems = this.state.rows.filter(row => row.selected)
    const areAllSelected = selectedItems.length === this.state.rows.length

    if (areAllSelected && !this.state.allRowsSelected) {
      this.setState({ allRowsSelected: true })
    }

    if (!areAllSelected && this.state.allRowsSelected) {
      this.setState({ allRowsSelected: false })
    }

    if (this.props.getSelectedItems && selectedItems.length > 0)
      this.sendSelectedItems(selectedItems)
  }

  handleMaincheckbox() {
    this.setState({ allRowsSelected: !this.state.allRowsSelected })
    this.setState({
      rows: this.state.rows.map(row => ({ ...row, selected: !this.state.allRowsSelected }))
    })
  }

  handleSingleCheckbox(id) {
    this.setState({
      rows: this.state.rows.map(row => ({
        ...row,
        selected: row.id === id ? !row.selected : row.selected
      }))
    })
  }

  sendSelectedItems(selectedItems) {
    this.props.getSelectedItems(
      selectedItems.map(item => {
        const clearedItem = { ...item }

        delete clearedItem.id
        delete clearedItem.selected

        return clearedItem
      })
    )
  }

  renderHeaders() {
    return this.state.columns.map((column, key) => {
      return column.type !== 'checkbox' ? (
        <Cell
          green={this.props.green}
          header
          ordered={column.order !== 'none'}
          key={key}
          className="uppercase"
          onClick={() => {
            this.clickSort(column.index, column.sortable)
          }}
        >
          {column.title}
          <i className="material-icons" aria-hidden="true">
            {column.icon}
          </i>
        </Cell>
      ) : (
        <Cell header ordered={column.order !== 'none'} key={key} className="uppercase">
          <CheckBox
            type="checkbox"
            checked={this.state.allRowsSelected}
            onChange={() => this.handleMaincheckbox()}
          />
        </Cell>
      )
    })
  }

  clickSort(indexString, sortable) {
    if (!sortable) return
    if (this.props.rows.length) {
      const columnOrder = this.state.columns.find(column => column.index === indexString)
      if (columnOrder.order === 'none' || columnOrder.order === 'desc') {
        columnOrder.icon = ARROW_ASC
        columnOrder.order = 'asc'
      } else {
        columnOrder.icon = ARROW_DESC
        columnOrder.order = 'desc'
      }

      const colsOrder = this.state.columns.map(column => {
        if (column.index === indexString) {
          return columnOrder
        }
        if (!column.sortable) {
          return column
        }
        return { ...column, icon: ARROW_NONE, order: 'none' }
      })

      this.setState({
        columns: colsOrder,
        sortedIndex: indexString
      })

      this.props.handleSort(indexString, columnOrder.order)
    }
  }

  renderRows() {
    const { columns, rows } = this.state

    return rows.map((row, key) => {
      const td = columns.map((col, k) => {
        return (
          <Cell key={k} autoHeight={this.props.autoHeightCells}>
            {typeof col.render === 'function' ? col.render(row) : row[col.index]}
            {col.index === 'select' && (
              <CheckBox
                type="checkbox"
                checked={row.selected}
                onChange={() => this.handleSingleCheckbox(row.id)}
              />
            )}
          </Cell>
        )
      })

      return <React.Fragment key={key}>{td}</React.Fragment>
    })
  }

  renderPagination() {
    return (
      <PaginationContainer>
        <ReactPaginate
          activeClassName="active"
          containerClassName="pagination pull-right"
          nextLabel={<i className={`material-icons ${this.props.totalPages===0 ? 'disabledButtons' : ''}`}>keyboard_arrow_right</i>}
          previousLabel={<i className="material-icons">keyboard_arrow_left</i>}
          pageCount={this.props.totalPages}
          pageRangeDisplayed={2}
          marginPagesDisplayed={1}
          onPageChange={this.props.onClickPage}
          forcePage={this.props.pagina ? this.props.pagina - 1 : 0}
          disabled={this.props.totalPages===0}
        />
        {this.props.children}
      </PaginationContainer>
    )
  }

  render() {
    return (
      <React.Fragment>
        {this.renderPagination()}
        <TableContainer columns={this.state.columns.map(col => col.size).join(' ')}>
          {this.renderHeaders()}
          {!this.props.searchedAndNoData && this.renderRows()}
        </TableContainer>
        {this.props.searchedAndNoData && <NoData> {this.state.noDataMsg}</NoData>}
      </React.Fragment>
    )
  }
}

export default Table

/**
 * Styles
 */
const PaginationContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0 10px 0;
  @media (max-width: ${props => props.theme.sizes.breakPoints.small}) {
    margin: 26px 0 10px 0;
    justify-content: space-around;
  }
  .pagination {
    display: flex;
    list-style-type: none;
    font-size: 14px;
    line-height: 24px;
    padding-left: 0;
    color: ${props => props.theme.colors.greyTwo};

    > li {
      width: 32px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      @media (max-width: ${props => props.theme.sizes.breakPoints.small}) {
        width: 29px;
        height: 29px;
      }
      border: 1px solid ${props => props.theme.colors.grey};
      :not(:first-child) {
        margin-left: 4px;
      }
      .disabledButtons{
        cursor: not-allowed;
        background: white;
        border: 1px solid ${props => props.theme.colors.grey};
        color: ${props => props.theme.colors.warmGrey};
        opacity: 0.3;
      }
      &.active {
        background: ${props => props.theme.colors.green};
        border: 1px solid ${props => props.theme.colors.green};
        color: white;
      }
      &.break {
        border: 0;
        padding: 0;
      }
      &.disabled {
        cursor: not-allowed;
        background: white;
        border: 1px solid ${props => props.theme.colors.grey};
        color: ${props => props.theme.colors.warmGrey};
        opacity: 0.3;
        > a {
          cursor: not-allowed;
        }
      }
      > a {
        display: flex;
      }
    }
  }
`
const NoData = styled.p`
  text-align: center;
  padding: 36px 0;
  font-size: 16px;
  font-weight: 600;
  color: ${props => props.theme.colors.greyTwo};
`
const CheckBox = styled.input`
  accent-color: ${props => props.theme.colors.green};
  :checked {
    background-color: ${props => props.theme.colors.green};
  }
`
