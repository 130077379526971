import React from 'react'
import styled from 'styled-components'
import { Formik } from 'formik'
import * as yup from 'yup'
import PropTypes from 'prop-types'
import moment from 'moment'

import { Paragraph, Button, Input, ErrorField } from '../styled/generics'

const validations = yup.object().shape({
  email: yup
    .string()
    .email('Email no válido')
    .required('Campo requerido'),
/*   phone: yup
    .string()
    .matches(/^[+]*[-\s./0-9]*$/, 'Teléfono no válido')
    .required('Campo requerido') */
    phone: yup
    .string()
    .required('Campo requerido')
    .matches(/^\d+$/, 'El teléfono debe contener solo números')
    .min(10, 'Ingresar un número de teléfono válido con el código de área')
    .max(12, 'El teléfono no puede exceder los 12 dígitos'),
})

const FormConfirmAppointment = props => (
  <Container>
    <Title>Información del turno</Title>
    <Details>
      {!props.isOnline && (
        <React.Fragment>
          <Span>{`${props.clinic.nombre}:`}</Span>
          <Value>{props.clinic.direccion}</Value>
        </React.Fragment>
      )}
      {props.specialty && (
        <React.Fragment>
          <Span>Especialidad:</Span>
          <Value>{props.specialty.nombre}</Value>
        </React.Fragment>
      )}
      {props.professional && (
        <React.Fragment>
          <Span>Profesional:</Span>
          <Value>{props.professional.nombreProfesional}</Value>
        </React.Fragment>
      )}
      {props.schedule && (
        <React.Fragment>
          <Span>Día y hora:</Span>
          <Value>{moment(props.schedule).format('DD/MM/YYYY - HH:mm')}</Value>
        </React.Fragment>
      )}
      <Span>Nombre del paciente:</Span>
      <Value>{props.patient.nombreApellido}</Value>
      <Span>Cobertura médica:</Span>
      <Value>{props.patient.coberturaMedica}</Value>
      <Span>Plan/Línea/Cartilla:</Span>
      <Value>{props.patient.planLineaCartilla}</Value>
      <Span>Número de Afiliado:</Span>
      <Value>{props.patient.nroAfiliado}</Value>
    </Details>
    <Formik
      initialValues={{
        phone: props.patient.telefono,
        email: props.patient.mail
      }}
      validationSchema={validations}
      onSubmit={props.onSubmit}
      render={renderProps => (
        <Form onSubmit={renderProps.handleSubmit}>
          <FormInputs>
            <Mandatory>
              <Input
                type="email"
                name="email"
                value={renderProps.values.email}
                placeholder="Email"
                warning={renderProps.errors.email && renderProps.touched.email ? 1 : 0}
                white={1}
              />
            </Mandatory>
            {renderProps.touched.email &&
              renderProps.errors.email && <ErrorField>{renderProps.errors.email}</ErrorField>}
            <Mandatory>
            <Input
                type="tel"
                name="phone"
                value={renderProps.values.phone}
                placeholder="Teléfono de contacto"
                warning={renderProps.errors.phone && renderProps.touched.phone ? 1 : 0}
                white={1}
                onChange={e => {
                  const value = e.target.value.replace(/\D/g, '');
                  renderProps.setFieldValue('phone', value);
                }}
                onBlur={renderProps.handleBlur}
              />
            </Mandatory>
            {renderProps.touched.phone &&
              renderProps.errors.phone && <ErrorField>{renderProps.errors.phone}</ErrorField>}
          </FormInputs>
          <ButtonsContainer>
            <SecondButton
              onClick={e => {
                e.preventDefault()
                props.onCancel()
              }}
            >
              Volver
            </SecondButton>
            <Button type="submit">Confirmar</Button>
          </ButtonsContainer>
        </Form>
      )}
    />
  </Container>
)

FormConfirmAppointment.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired
}

export default FormConfirmAppointment

/**
 * Styles
 */
const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
`
const Form = styled.form`
  width: 100%;
`
const FormInputs = styled.div`
  width: 520px;
  margin: 32px auto 0 auto;
  @media (max-width: ${props => props.theme.sizes.breakPoints.small}) {
    width: 100%;
  }
`
const ButtonsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 39px;
  margin-top: 30px;
  @media (max-width: ${props => props.theme.sizes.breakPoints.small}) {
    grid-template-columns: 1fr;
    grid-gap: 12px;
  }
`
const Title = styled(Paragraph)`
  margin: 30px 0;
  font-weight: 600;
  font-size: 24px;
  color: ${props => props.theme.colors.green};
  text-align: center;
  @media (max-width: ${props => props.theme.sizes.breakPoints.small}) {
    margin: 20px 0;
  }
`
const Details = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px 40px;
  width: 100%;
  font-size: 14px;
  @media (max-width: ${props => props.theme.sizes.breakPoints.small}) {
    font-size: 13px;
    grid-gap: 13px 3px;
  }
`
const Span = styled.span`
  justify-self: end;
  color: ${props => props.theme.colors.warmGreyTwo};
`
const Value = styled.span`
  color: ${props => props.theme.colors.green};
  font-weight: bold;
`
const Mandatory = styled.div`
  display: flex;
  flex-direction: row;
  &:not(:first-child) {
    margin-top: 30px;
  }
  &:last-child {
    margin-bottom: 16px;
  }
  @media (max-width: ${props => props.theme.sizes.breakPoints.small}) {
    &:not(:first-child) {
      margin-top: 20px;
    }
    &:last-child {
      margin-bottom: 7px;
    }
  }
`

const SecondButton = styled(Button)`
  @media (max-width: ${props => props.theme.sizes.breakPoints.small}) {
    grid-row: 2;
  }
`
