import React from 'react'
import { Formik } from 'formik'
import * as yup from 'yup'
import styled from 'styled-components'
import { withRouter } from 'react-router-dom'

import { Input, Button, Select, Textarea, ErrorField} from '../styled/generics'
import withUser from '../hoc/withUser';

const validations = yup.object().shape({
  name: yup.string().required(),
  lastName: yup.string().required(),
  phone: yup
  .string()
  .required()
  .matches(/^\d+$/, 'El teléfono debe contener solo números')
  .min(10, 'Ingresar un número de teléfono válido con el código de área')
  .max(12, 'El teléfono no puede exceder los 12 dígitos'),
  message: yup.string().required(),
  clinic: yup.string().required()
})


const initialValues = {
  name: '',
  lastName: '',
  phone: '',
  message: '',
  clinic: '',
}

const FormContact = props => {

initialValues.email = props.auth.email
initialValues.roles = props.auth.roles

return (
  <Formik
    onSubmit={props.onSubmit}
    initialValues={initialValues}
    validationSchema={validations}
    render={renderProps => (
      <Form onSubmit={renderProps.handleSubmit}>
        <Input
          type="text"
          name="name"
          value={renderProps.values.name}
          placeholder="Nombre"
          white={1}
          border={1}
          warning={renderProps.errors.name && renderProps.touched.name ? 1 : 0}
        />
        <Input
          type="text"
          name="lastName"
          value={renderProps.values.lastName}
          placeholder="Apellido"
          white={1}
          border={1}
          warning={renderProps.errors.lastName && renderProps.touched.lastName ? 1 : 0}
        />
        <div>
          <Input
            type="text"
            name="phone"
            value={renderProps.values.phone}
            placeholder="Teléfono"
            white={1}
            border={1}
            warning={renderProps.errors.phone && renderProps.touched.phone ? 1 : 0}
          />
          {renderProps.errors.phone && renderProps.touched.phone && <ErrorMessageContainer>{renderProps.errors.phone}</ErrorMessageContainer>}
          <Select
            component="select"
            name="clinic"
            warning={renderProps.errors.clinic && renderProps.touched.clinic ? 1 : 0}
          >
            <option value="" disabled hidden>
              Seleccione una clínica
            </option>
            {props.listOfClinics.map((clinic, idx) => (
              <option value={clinic.mail} key={idx}>
                {clinic.nombre}
              </option>
            ))}
          </Select>
        </div>

        <Textarea
          component="textarea"
          name="message"
          placeholder="Mensaje"
          value={renderProps.values.message}
          border={1}
          warning={renderProps.errors.message && renderProps.touched.message ? 1 : 0}
        />

        <Button onClick={() => props.history.push('/inicio')}>Volver</Button>
        <Button type="submit">Consultar</Button>
      </Form>
    )}
  />
)}

export default withRouter(withUser(FormContact))

const Form = styled.form`
  input[type='text'],
  textarea,
  select {
    margin-bottom: 20px;
  }

  button[type='submit'] {
    margin-top: 20px;
  }

  @media (min-width: ${props => props.theme.sizes.breakPoints.small}) {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    input,
    select {
      width: 370px;
    }

    button {
      display: inline;
      width: 320px;

      @media (min-width: ${props => props.theme.sizes.breakPoints.medium}) { 
        &[type='submit'] {
          margin-top: 0;
          margin-left: 20px;
        }
      }
    }
  }

  @media (min-width: ${props => props.theme.sizes.breakPoints.medium}){
    input[name='name'] {
      margin-right: 20px;
    }
  }
`
const ErrorMessageContainer = styled(ErrorField)`
  margin-bottom:20px;
`